import React, { useEffect, useState } from 'react';
import Facebook from '../../assets/icons/facebook.svg';
import Twitter from '../../assets/icons/twitter.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Logo from '../ui/Logo';
import s from './Layout.module.css';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const [activeLink, setActiveLink] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);
  return (
    <div className={s.footerWrapper + (activeLink !== '/' ? ' !pt-28' : '')}>
      <div className="text-center lg:max-w-screen-xl flex justify-center flex-col w-full mx-auto px-6 sm:px-8 lg:px-16 gridx grid-rows-2 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4">
        <div className="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col items-center ">
          <div className=" w-auto mb-6">
            <Logo orientation="v" />
          </div>
          <div className="flex mt-2 mb-8 -mx-2">
            <div className="mx-2 bg-white rounded-full items-center justify-center flex p-2 shadow-md">
              <img
                src={Facebook}
                style={{ filter: 'hue-rotate(335deg)' }}
                className="h-6 w-6 text-accent"
                alt=""
              />
            </div>
            <div className="mx-2 bg-white rounded-full items-center justify-center flex p-2 shadow-md">
              <img
                src={Twitter}
                style={{ filter: 'hue-rotate(335deg)' }}
                className="h-6 w-6"
                alt=""
              />
            </div>
            <div className="mx-2 bg-white rounded-full items-center justify-center flex p-2 shadow-md">
              <img
                src={Instagram}
                style={{ filter: 'hue-rotate(335deg)' }}
                className="h-6 w-6"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="text-center text-sm flex justify-center flex flex-col mb-6">
          <ul className="text-white flex flex-wrap justify-center">
            <li className="my-2 px-4 hover:text-accent cursor-pointer transition-all">
              Home
            </li>
            <li className="my-2 px-4 hover:text-accent cursor-pointer transition-all">
              FAQ{' '}
            </li>
            <li className="my-2 px-4 hover:text-accent cursor-pointer transition-all">
              About Us{' '}
            </li>
            <li className="my-2 px-4 hover:text-accent cursor-pointer transition-all">
              Privacy Policy{' '}
            </li>
            <li className="my-2 px-4 hover:text-accent cursor-pointer transition-all">
              Terms of Service{' '}
            </li>
          </ul>
        </div>

        <p className="text-white text-sm">
          ©{new Date().getFullYear()} - MyBrain
        </p>
      </div>
    </div>
  );
};

export default Footer;
