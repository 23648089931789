import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createRoutesFromElements,
  Routes
} from 'react-router-dom';

import Layout from '../components/Layout';
import Landing from '../pages/Landing';
import Search from '@pages/Search';
import Service from '@pages/Service';
import ContactPage from '@pages/Contact';
import PortfolioPage from '@pages/Portfolio';

// const router = createRoutesFromElements(

// );

export default (
  <Routes>
    <Route path={`${process.env.PUBLIC_URL}/`} element={<Layout />}>
      <Route index element={<Landing />} />
      <Route
        path={`${process.env.PUBLIC_URL}/services/:id`}
        element={<Service />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/contact`}
        element={<ContactPage />}
        // loader={({ request }) =>
        //   fetch('/api/dashboard.json', {
        //     signal: request.signal
        //   })
        // }
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portfolio`}
        element={<PortfolioPage />}
      />

      {/* <Route element={<AuthLayout />}>
    <Route path="login" element={<Login />} loader={redirectIfUser} />
    <Route path="logout" action={logoutUser} />
  </Route> */}
    </Route>
  </Routes>
);
