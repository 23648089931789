import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button, ScrollAnimationWrapper } from '@components/common';
import { bannerStatistics } from '@data/dynamic';
import s from './Banner.module.css';
import data from '@data/static.json';
import { LoadingCircle } from '@components/ui/icons';
import getScrollAnimation from '@utils/getScrollAnimation';
import homeImg from '@assets/images/illustrations/stand-dis-trans.png';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import sliderImg1 from '@assets/images/slider/11.jpg';
import sliderImg2 from '@assets/images/illustrations/work-pc-crop.jpg';
import sliderImg3 from '@assets/images/slider/33.jpg';

const Banner = () => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  const navigate = useNavigate();
  const bannerData = data.bannerData;
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  let banners = [
    // {
    //   title:
    //     'Want anything to be easy with <strong class="text-accent mt-4">MyBrain</strong>',
    //   img: sliderImg1
    // },
    {
      title:
        'Empowering businesses with tailored software development services',
      subtitle:
        'We tailor solutions to meet your unique needs, driving your success in the digital landscape.',
      img: sliderImg2
    },
    {
      title:
        'Crafting exceptional web and mobile applications for your success',
      subtitle:
        'We blend innovation and user-centric design to propel your business forward with exceptional web and mobile applications.',
      img: sliderImg3
    }
  ];
  return (
    <div className="relative">
      <div className={s.lineMask}></div>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={6000}>
        {[...banners].map((item) => (
          <div className="container mx-auto slider-item my-8 mt-20 md:mb-0">
            <div className="container mx-auto max-w-screen-xl lg:px-8 xl:px-8 mx-auto">
              <ScrollAnimationWrapper>
                {/* <motion.div custom={{ duration: 2 }} variants={scrollAnimation}> */}
                <div className={s.searchBanner}>
                  <div className="container">
                    <div className="mx-auto mt-28 lg:mt-0 lg:px-12 lg:mt-0 grid lg:grid-cols-9 gap-8 relative z-30 flex-col lg:flex-row items-center py-16 lg:py-20 pb-16 lg:pb-16 lg:h-auto justify-center">
                      <div className="col-span-9 lg:col-span-5 self-center">
                        <div className="flex-grow pr-0 lg:pr-4 pb-0 lg:pb-8 text-center mt-8 lg:mt-0 lg:mb-0 flex flex-col items-center justify-center">
                          <div className="flexx text-left flex-col items-center justify-center px-6 lg:px-0">
                            <h2
                              className="text-gray-500 font-bold text-2xl flexx flex-col md:text-4xl lg:text-6xl font-extrabold mb-8"
                              style={{ lineHeight: 1.2 }}
                              dangerouslySetInnerHTML={{
                                __html: item.title
                              }}></h2>
                            <p className="text mb-12 text-secondary lg:w-3/4">
                              {item.subtitle}
                            </p>
                            <Button className="btn btn-primary" size="lg">
                              <Link to="/contact"> Get Started</Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-9 lg:col-span-4 self-start mb-6">
                        <div className="flex-grow pb-0 lg:pb-8 text-center lg:mb-0 flex flex-col items-center justify-center">
                          <div className="flexx text-center flex-col items-center justify-center">
                            <img
                              src={homeImg}
                              alt=""
                              style={{
                                filter:
                                  'hue-rotate(380deg) sepia(0.25) saturate(1.2) brightness(1) contrast(1.05)'
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </motion.div> */}
              </ScrollAnimationWrapper>
            </div>
          </div>
        ))}
      </AutoplaySlider>

      <div>
        <div className="max-w-screen-xl lg:px-8 xl:px-16 mx-auto">
          <div
            className="relative w-full flex shadow-xl shadow-gray-200 rounded-lg "
            style={{ marginBottom: -80 }}>
            <ScrollAnimationWrapper className="bg-black rounded-lg w-full grid grid-flow-row sm:grid-flow-row grid-cols-1 sm:grid-cols-3 py-9 divide-y-2 sm:divide-y-0 sm:divide-x-2 divide-gray-100 -500 z-10">
              {bannerStatistics.map((listUsers, index) => (
                <motion.div
                  className="flex items-center justify-start sm:justify-center py-4 sm:py-6 w-8/12 px-4 sm:w-auto mx-auto sm:mx-0"
                  key={index}
                  custom={{ duration: 2 + index }}
                  variants={scrollAnimation}>
                  <div className="flex mx-auto w-40 sm:w-auto">
                    <div className="flex items-center justify-center bg-white w-12 h-12 mr-6 rounded-full">
                      <img
                        src={listUsers.icon}
                        className="h-6 w-6"
                        style={{ filter: 'hue-rotate(334deg)' }}
                      />
                    </div>
                    <div className="flex flex-col">
                      <p className="text-xl text-white font-bold">
                        {listUsers.number}
                      </p>
                      <p className="text-lg text-gray-400">{listUsers.name}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </ScrollAnimationWrapper>
            <div
              className="absolute bg-black-600 opacity-5 w-11/12 roudned-lg h-64 sm:h-48 top-0 mt-8 mx-auto left-0 right-0"
              style={{ filter: 'blur(114px)' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
