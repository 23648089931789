import UserIcon from '../assets/icons/heroicons_sm-user.svg';
import LocIcon from '../assets/icons/gridicons_location.svg';
import ServerIcon from '../assets/icons/bx_bxs-server.svg';
import Img from '@assets/images/home.jpg';

export const bannerStatistics = [
  {
    name: 'Partners',
    number: '100+',
    icon: UserIcon
  },
  {
    name: 'Countries',
    number: '10',
    icon: LocIcon
  },
  {
    name: 'Projects',
    number: '120+',
    icon: ServerIcon
  }
];

export const portfolios = [
  {
    domain: 'Real-estate industry – property technology (Proptech)  ',
    name: 'Wurley',
    type: 'Propelling Property Technology to New Heights in Australia',
    description:
      'With a profound understanding of the Australian property market, we have developed Wurly, our cutting-edge property technology solution. Designed specifically for the Australian market, Wurly offers a comprehensive range of affordable services, including property buying, renting, sales, rental property management, mortgage loans, and conveyancing services. Our recent commercial launch marks our commitment to providing innovative and accessible solutions for the property industry. ',
    logo: '',
    banner: Img,
    link: 'https://wurley.com.au',
    extra:
      'Contact us to transform your real estate projects into vibrant realities with our expertise and bring your imagination to life!     '
  },
  {
    domain: 'Sustainability',
    name: 'E-invoice',
    type: 'All invoices at one place for a greener world',
    description:
      "<p class='mb-4'>Simplifying the complexity of invoice management, we have developed a streamlined platform that consolidates invoices from various point-of-sale (POS) systems into a single solution. Our Australian-based client entrusted us to transform this intricate process into a user-friendly product. </p><p class='mb-4'>With our profound expertise in POS systems tailored for the hospitality and retail industries, you can place your trust in us for your upcoming projects. Reach out to us to discover how we can assist you with your invoicing and POS system needs.</p>",
    logo: '',
    banner: Img,
    link: 'http://einvoice.com.bd/',
    extra: ''
  },
  {
    domain: 'Finance',
    name: 'iPay',
    type: 'Financial technology (Fintech)',
    description:
      "As believers in a future cashless society, we are proud to be at the forefront of Australia's journey towards becoming a cashless nation. With our extensive expertise in the fintech industry, we are well-equipped to handle projects in areas such as e-wallets, payment gateways, and various digital payment services. Our team of accomplished developers has led successful implementations on numerous international payment service projects    ",
    logo: '',
    banner: Img,
    link: 'https://www.i-pay-solutions.com/',
    extra:
      ' Contact us today to explore how we can drive innovation and growth in the fintech landscape together.    '
  },
  {
    domain: 'Retail and online - E-commerce',
    name: 'FShop',
    description:
      'With our lead developers at the helm, we have successfully led the development of numerous ecommerce platforms, implementing cutting-edge technology to deliver impressive customer experiences. Our expertise extends to areas such as intuitive UI/UX design, seamless payment gateway integration, efficient logistics solutions, and robust inventory management.',
    type: 'E-commerce',
    banner: Img,
    link: 'https://fshop.com.bd',
    extra:
      'Leverage our experience and knowledge to guide you through the process of building your own exceptional e-commerce platform. Contact us today to embark on this exciting journey together!  '
  },
  {
    domain: 'Retail and online - E-commerce',
    name: 'Evaly',
    description:
      'With our lead developers at the helm, we have successfully led the development of numerous ecommerce platforms, implementing cutting-edge technology to deliver impressive customer experiences. Our expertise extends to areas such as intuitive UI/UX design, seamless payment gateway integration, efficient logistics solutions, and robust inventory management.',
    type: 'E-commerce',
    banner: Img,
    link: 'https://evaly.com.bd',
    extra:
      'Leverage our experience and knowledge to guide you through the process of building your own exceptional e-commerce platform. Contact us today to embark on this exciting journey together!  '
  }
];
