import { Container } from '@components/ui';
import {
  FaBuyNLarge,
  FaGripVertical,
  FaHandLizard,
  FaHandMiddleFinger,
  FaMoneyBill,
  FaMoneyCheck,
  FaRegFileArchive,
  FaRegGrinTears,
  FaRegHandshake,
  FaRegMoneyBillAlt,
  FaSellcast,
  FaWind
} from 'react-icons/fa';
import s from './Service.module.css';
import Img from '@assets/images/illustrations/loc.jpg';

const Service = () => {
  return (
    <div id="feature" className="bg-white">
      <Container>
        <div className="grid gap-3 grid-cols-12">
          <div className="col-span-12 lg:col-span-6">
            <div className="relative flex items-end pt-12 h-full mr-6 mb-4">
              <span className={s.buyCircle}>
                Accuracy <div className="text-black ml-1">100%</div>
              </span>
              <span className={s.sellCircle}>
                Ontime <span className="text-black ml-1">99%</span>{' '}
              </span>
              <span className={s.rentCircle}>
                Secured <div className="text-black ml-1">100%</div>{' '}
              </span>
              <img
                src={Img}
                // width={850}
                // height={678}
                alt="Service img"
                className={s.serviceImg}
              />
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 flex flex-col justify-center mb-8 lg:py-8 lg:py-20">
            <h1 className="font-bold text-2xl lg:text-5xl mb-4 lg:mb-6">
              Your Dream Project
            </h1>

            <h4 className="text-secondary lg:mb-6 lg:ml-4">
              Let’s work together on your next web project
            </h4>

            <div className="grid lg:grid-cols-2 gap-1 container mt-4">
              {[
                {
                  title: 'Contact',
                  description: 'Contact with use via email',
                  icon: <FaRegMoneyBillAlt className="text-xl" />
                },
                {
                  title: 'Discuss',
                  description: 'Explain your requirement',
                  icon: <FaWind className="text-xl" />
                },
                {
                  title: 'Ready',
                  description: 'We make your project ready',
                  icon: <FaRegHandshake className="text-xl" />
                },
                {
                  title: 'Handover',
                  description: 'We make your project ready',
                  icon: <FaRegHandshake className="text-xl" />
                }
              ].map(({ title, description, icon }, index) => (
                <div
                  className={
                    'col-span-1 border-none lg:col-span-1 ' +
                    (index % 2 == 0 ? 'border-r lg:pr-2 ' : 'border-l lg:pl-2 ') +
                    (index < 2 ? 'border-b lg:pb-2 ' : 'border-t lg:pt-2 ')
                  }
                  key={String(index)}>
                  <div className="px-6 py-6 h-30 h-full shadow-gray-200 shadow-lg rounded-xl flex items-center">
                    <div className="bg-accent rounded-full h-12 w-12 text-white flex items-center justify-center">
                      {icon}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column'
                      }}>
                      <h6
                        className="mb-1 pl-4 uppercase"
                        style={{
                          fontSize: '1rem'
                        }}>
                        {title}
                      </h6>
                      <h4 className="text-gray-400 pl-4 text-sm">
                        {' '}
                        {description}
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Service;
