import ScrollToTop from '@components/common/ScrollToTop';
import routes from './routes';

function App() {
  return (
    <>
      {console.log(process.env.PUBLIC_URL)}
      <ScrollToTop />
      {routes}
    </>
  );
}

export default App;
