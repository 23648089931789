import { Container } from '@components/ui';
import React, { FC, useRef } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaBackward,
  FaForward
} from 'react-icons/fa';
import Slider, { Settings } from 'react-slick';
import { ScrollAnimationWrapper } from '@components/common';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import getScrollAnimation from '@utils/getScrollAnimation';
import Img2 from '@assets/images/illustrations/time-progress-crop.jpg';
import { portfolios } from '@data/dynamic';

const SliderArrow = (props) => {
  const { onClick, type, className } = props;

  return (
    <span
      style={{
        backgroundColor: 'background.paper',
        color: 'primary.main',
        bottom: '64px !important',
        left: 'unset !important',
        right: type === 'prev' ? '90px !important' : '30px !important',
        zIndex: 10
      }}
      color="inherit"
      onClick={onClick}
      className={
        className +
        ' bg-white shadow-lg cursor-pointer hover:text-accent p-4 rounded-full'
      }>
      {type === 'next' ? <FaArrowRight /> : <FaArrowLeft />}
    </span>
  );
};

const StyledSlickContainer = ({ children }) => (
  <div className="slick-con">{children}</div>
);

const Portfolio = () => {
  const sliderRef = useRef(null);
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);
  const list = portfolios;
  const sliderConfig = {
    // infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />
  };

  return (
    <div
      id="testimonial"
      className="testimonial-page py-6 bg-light mt-12 lg:mt-24 lg:pt-0 pb-20">
      <div className="py-8 pb-0 mb-20 bg-white">
        <Container>
          <div className="flex pb-8 lg:pb-12 mt-4 lg:mt-0 justify-between mb-0">
            <div className="border-l-8 border-gray-200">
              <h4 className="text-5xl text-black font-bold pl-4">Portfolio</h4>
            </div>
            {/* <img src={Img2} alt="" className="h-40" /> */}
          </div>
        </Container>
      </div>
      <Container>
        <ScrollAnimationWrapper className="rounded-lg w-full">
          {list?.map((item, i) => {
            return (
              <div className="section-wrap lg:!mb-12">
                <div
                  // custom={{ duration: 2 }}
                  // variants={scrollAnimation}
                  className="grid gap-6 lg:grid-cols-2">
                  <div
                    className={
                      'testimonial-box mb-6 h-80 self-center ' +
                      (i % 2 == 0 ? ' lg:order-0' : ' lg:order-1')
                    }>
                    <img
                      className=" object-cover !rounded-none border-4 outline-4 !p-6"
                      src={item.banner}
                      alt="logo"
                    />
                  </div>

                  <div
                    className={
                      'testimonial-box self-center py-6 mb-6 !shadow-none !p-4 !items-start !bg-transparent !text-left ' +
                      (i % 2 == 0 ? '  order-1' : '!items-end order-0')
                    }>
                    <h2 className="text-xl mb-4 font-bold text-gray-600">
                      {item.name}
                    </h2>
                    <p
                      className={
                        'text-base text-gray-400 mb-4 ' +
                        (i % 2 == 0 ? '' : ' !text-right')
                      }
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <div className="flex items-center">
                      <div className="text-sm text-gray-400 font-bold mr-2">
                        Website
                      </div>
                      <div className="text-sm text-accent">
                        <a href="https://twitter.com/lytbox_co/status/1086847076376313856">
                          @{item.name}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </ScrollAnimationWrapper>
      </Container>
    </div>
  );
};

export default Portfolio;
