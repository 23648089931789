import React, { useMemo } from 'react';
import Maps from '../../../assets/images/HugeGlobal.svg';
import { motion } from 'framer-motion';
import getScrollAnimation from '@utils/getScrollAnimation';
import { Button, ScrollAnimationWrapper } from '@components/common';
import s from './Map.module.css';
import { useNavigate } from 'react-router-dom';
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Marker,
  Sphere
} from 'react-simple-maps';
import { geoWinkel3 } from 'd3-geo-projection';

const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';

const Map = () => {
  const navigate = useNavigate();
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  const width = 800;
  const height = 600;

  const screen = window.matchMedia('(max-width: 400px)');

  const projection = geoWinkel3()
    .translate([
      width / (screen?.matches ? 2 : 2.25),
      height / (screen?.matches ? 2 : 2.7)
    ])
    .scale(screen?.matches ? 180 : 150);

  const coors = [
    {
      name: 'Australia',
      coordinates: [-24.7761086, 134.755]
    },
    {
      name: 'Asia',
      coordinates: [51.2086975, 89.2343748]
    },
    {
      name: 'USA',
      coordinates: [39.7837304, -100.445882]
    },
    {
      name: 'Germany',
      coordinates: [51.1638175, 10.4478313]
    }
  ];

  return (
    <div className="bg-gray-100 pb-20 w-full" id="map">
      <div className="py-20">
        <div className="flex  flex-col w-full max-w-screen-xl mx-auto">
          {/* <ScrollAnimationWrapper className="relative w-full">
            <motion.div variants={scrollAnimation} custom={{ duration: 3 }}> */}
          <div
            className={
              ' rounded-2xl text-center w-full flex flex-col justify-center items-center'
            }>
            <div className="w-10/12 sm:w-7/12 lg:w-5/12 mb-12">
              <h2 className="text-5xl font-bold text-gray-500 mb-4">
                Our Service Points
              </h2>
              <p className="text-sm text-gray-400">
                We are the team build on all around the world!
              </p>
            </div>
            <ComposableMap
              width={screen?.matches ? width/.9 : width/1.05}
              height={screen?.matches ? height : height / 1.8}
              // @ts-ignore
              projection={projection}>
              <Graticule stroke="none" />
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography key={geo.rsmKey} geography={geo} />
                  ))
                }
              </Geographies>
              <Sphere id="" stroke="none" fill="none" strokeWidth={2} />
              {coors?.map((coor) => (
                <>
                  {/* @ts-ignore */}
                  <Marker coordinates={coor.coordinates?.reverse()}>
                    <text
                      textAnchor="middle"
                      accentHeight="114px"
                      fill="var(--primary-color)"
                      className="mb-4 py-4 !text-4xl lg:!text-xs"
                      style={{
                        // fontSize: 10,
                        transform: 'translateY(-10px)',
                        textShadow: '0px 1px 1px black'
                      }}>
                      {coor.name}
                    </text>
                    <br />
                    <circle r={5} stroke="white" fill="var(--accent-color)" />
                  </Marker>
                </>
              ))}
            </ComposableMap>
          </div>
          {/* </motion.div>
          </ScrollAnimationWrapper> */}
        </div>
      </div>
    </div>
  );
};

export default Map;
